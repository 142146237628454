export default function collaborators() {

  // if ($('.collaborators-filter').height() > $(window).height()) {
  //   $('.collaborators-filter').css('transform', 'none');
  //   $('.collaborators-filter').css('position', 'absolute');
  //   $('.collaborators-filter').css('top', '25vh');
  // }

  $('.collaborators-filter a').click(function(e){
    e.preventDefault();

    if ($(this).hasClass('filter-search')) {
      $('.collaborators-search').show();
      $('html, body').animate({scrollTop: 0}, 800);
    } else {
      location = $('#form-collaborators').attr('action') + '?col_filter=' + $(this).data('letter');
    }
  });

  $('.collaborators-search__close a').click(function(e){
    e.preventDefault();
    $('.collaborators-search').hide();
  });

  $('.position-list .item-thumb__inactive a').click(function(e){
    e.preventDefault();
    e.stopPropagation();
    return false;
  });

  $('.person-type-list .item-thumb__inactive a').click(function(e){
    e.preventDefault();
    e.stopPropagation();
    return false;
  });

}