export default function single_news() {
  var width = $(window).width();
  var count = $('.related-news__list .owl-carousel').data('count');
  var loop = (width < 768 && count >= 2) || (width >= 768 && count >= 4);

  $('.related-news__list .owl-carousel').owlCarousel({
    items: 1.5,
    margin: 0,
    dotsEach: true,
    loop: loop,
    nav: true,
    autoplay: false,
    smartSpeed: 1000,
    mouseDrag: false,
    touchDrag: false,
    autoWidth: false,
    autoplayHoverPause: true,
    navText: ['<i class="fal fa-arrow-left"></i>','<i class="fal fa-arrow-right"></i>'],
    responsive: {
      768: {
        items: 3
      }
    }
  });

  $('.related-news__title .btn-prev').click(function(e){
    e.preventDefault();
    $('.related-news__list .owl-carousel').trigger('prev.owl.carousel');
  });

  $('.related-news__title .btn-next').click(function(e){
    e.preventDefault();
    $('.related-news__list .owl-carousel').trigger('next.owl.carousel');
  });

  if (loop == false) {
    $('.related-news__title .more').hide();
  }
}