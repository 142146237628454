export default function films() {
  $('#film_filter').select2();

  $('#film_filter').on('select2:select', function (e) {
    filter_films();
  });

  $('.filters .sort-by ul li a').click(function(e){
    e.preventDefault();

    $('#film_sort').data('order', $(this).data('order'));
    $('#film_sort').addClass('selected');
    $('#film_order').removeClass('selected');

    filter_films();
  });

  $('#film_order').click(function(e){
    e.preventDefault();

    $('#film_sort').removeClass('selected');
    $('#film_order').addClass('selected');

    if ($(this).data('order') == 'asc') {
      $(this).data('order', 'desc');
      $(this).html('Z - A');
    } else {
      $(this).data('order', 'asc');
      $(this).html('A - Z');
    }

    filter_films();
  });

  $('#film_search').keypress(function (e) {
    if ( e.keyCode == 13 ) {
      filter_films();
    }
  });

  function filter_films() {
    var filter_url = $('.filters').data('url');
    var params = [];

    if ( $('#film_filter').val() != '' ) {
      params.push( 'film_filter=' + $('#film_filter').val() );
    }

    if ($('#film_sort').hasClass('selected')) {
      params.push( 'film_sort=' + $('#film_sort').data('sort') );
      params.push( 'film_order=' + $('#film_sort').data('order') );
    }
    
    if ($('#film_order').hasClass('selected')) {
      params.push( 'film_sort=' + $('#film_order').data('sort') );
      params.push( 'film_order=' + $('#film_order').data('order') );
    }

    if ( $('#film_search').val() != '' ) {
      params.push( 'film_search=' + $('#film_search').val() );
    }

    $('.film-list').html('<div class="loading"><i class="fal fa-sync fa-spin"></i></div>');

    $.ajax({
      url: filter_url + '?' + params.join('&'),
      type: 'GET',
      success: function(res) {
        $('.film-list').html( $(res).find('.film-list').html() );
      }
    });
  }
}