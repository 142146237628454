export default function home_store() {
	
	$('.home-store .store-products').owlCarousel({
    items: 1,
    dotsEach: true,
    loop: true,
    nav: true,
    smartSpeed: 1000,
    mouseDrag: false,
    touchDrag: false, 
    navText: ['<img class="btn-left" src="' + wp.site_url + '/wp-content/themes/milesdavis/dist/img/arrow-left.png">', '<img class="btn-right" src="' + wp.site_url + '/wp-content/themes/milesdavis/dist/img/arrow-right.png">'], 
    onInitialized: function(event) {
      console.log(event);
      $('.home-store .owl-page .current').html(('0' + 1).slice(-2));
      $('.home-store .owl-page .count').html(('0' + event.item.count).slice(-2));
    },
    onChanged: function(event) {
      $('.home-store .owl-page .current').html(('0' + (event.page.index + 1)).slice(-2));
      $('.home-store .owl-page .count').html(('0' + event.page.count).slice(-2));
    }
  });
	
}