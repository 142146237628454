export default function music_playlist() {

  $('.playlist-types a').click(function(e){
    e.preventDefault();

    $('.playlist-content').hide();
    $($(this).attr('href')).show();

    $('.playlist-types a').removeClass('active');
    $(this).addClass('active');
  });
}