export default function home_news() {
	
	$('.home-news .news-list').owlCarousel({
    items: 1.2,
    dotsEach: true,
    loop: true,
    nav: true,
    smartSpeed: 1000,
    mouseDrag: false,
    touchDrag: false,
    navText: ['<i class="fal fa-arrow-left"></i>','<i class="fal fa-arrow-right"></i>'],
    responsive: {
      768: {
        items: 3
      }
    }
  });
	
}