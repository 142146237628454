export default function awards() {

  function initPhotoPopup() {
    $('.award-list .award').each(function(){
      $(this).find('.award-photos').magnificPopup({
        delegate: 'a',
        type: 'image',
        tLoading: 'Loading image #%curr%...',
        mainClass: 'mfp-img-mobile',
        verticalFit: true,
        closeMarkup: '<img class="mfp-close" src="' + wp.site_url + '/wp-content/themes/milesdavis/dist/img/close.png">',
        gallery: {
          enabled: true,
          navigateByImgClick: true,
          preload: [0,1],
          arrowMarkup: '<img class="btn-%dir%" src="' + wp.site_url + '/wp-content/themes/milesdavis/dist/img/arrow-%dir%.png">', 
          tCounter: '<span>%curr%</span> / %total%'
        },
        image: {
          tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
          titleSrc: function(item) {
            return item.el.attr('title');
          }
        },
        callbacks: {
          change: function(){
            console.log($('.mfp-counter span').html());
            if (this.index < 9) {
              var _num = this.index + 1;
              setTimeout(function(){
                $('.mfp-counter span').html('0' + _num);
              }, 10)
              
            }
          }
        }
      });

      $(this).find('.award-links a.photo').click(function(e){
        e.preventDefault();
        $(this).parent().find('.award-photos a:eq(0)').click();
      });
    });
  }

  function initVideoPopup() {
    $('.award-list .award').each(function(){
      $(this).find('.award-videos').magnificPopup({
        delegate: 'a',
        type: 'iframe',
        mainClass: 'mfp-fade',
        verticalFit: true,
        closeMarkup: '<img class="mfp-close" src="' + wp.site_url + '/wp-content/themes/milesdavis/dist/img/close.png">',
        gallery: {
          enabled: true,
          navigateByImgClick: true,
          preload: [0,1],
          arrowMarkup: '<img class="btn-%dir%" src="' + wp.site_url + '/wp-content/themes/milesdavis/dist/img/arrow-%dir%.png">', 
          tCounter: '<span>%curr%</span> / %total%'
        },
        callbacks: {
          change: function(){
            console.log($('.mfp-counter span').html());
            if (this.index < 9) {
              var _num = this.index + 1;
              setTimeout(function(){
                $('.mfp-counter span').html('0' + _num);
              }, 10)
              
            }
          }
        }
      });

      $(this).find('.award-links a.video').click(function(e){
        e.preventDefault();
        $(this).parent().find('.award-videos a:eq(0)').click();
      });
    });
  }

  function initFilters() {
    $('.page-awards .filters .filter select').select2();

    $('.page-awards .filters .filter select').on('select2:select', function (e) {
      filter_awards();
    });
  }

  function filter_awards() {
    var filter_url = $('.filters').data('url');
    var params = [];

    if ( $('#filter-year').val() != '' ) {
      params.push( 'fawd_year=' + $('#filter-year').val() );
    }

    if ( $('#filter-type').val() != '' ) {
      params.push( 'fawd_type=' + $('#filter-type').val() );
    }

    if ( $('#filter-result').val() != '' ) {
      params.push( 'fawd_result=' + $('#filter-result').val() );
    }

    $('.award-list').html('<div class="loading"><i class="fal fa-sync fa-spin"></i></div>');

    $.ajax({
      url: filter_url + '?' + params.join('&'),
      type: 'GET',
      success: function(res) {
        $('.award-list').html( $(res).find('.award-list').html() );
        initPhotoPopup();
        initVideoPopup();
      }
    });
  }

  $(document).ready(function() {
    initPhotoPopup();
    initVideoPopup();
    initFilters();
  });
	
}