export default function nav() {

  $('#main').css('padding-top', $('#header').height());

  $(window).scroll(function(){
    if ($(window).scrollTop() > $('#header').height()) {
      $('#header').addClass('sticky');
    } else {
      $('#header').removeClass('sticky');
    }
  });
	
	$('#header .hamburger').on('click', function (e) {
		e.preventDefault();
    $( 'body' ).toggleClass( 'menu-open' );
		$( this ).toggleClass( 'is-active' );
		$( '.nav-container' ).toggleClass( 'active' );

    if ($(this).hasClass('is-active')) {
      $(this).find('span').html('Close');
    } else {
      $(this).find('span').html('Menu');
    }
  });

  $('#footer .scroll-top').click(function(){
    $('html, body').animate({
      scrollTop: 0
    }, 800);
  });
  
}