export default function videos() {

  function initVideos() {
    $('.page-videos .video-list .item .action a').click(function(e){
      e.preventDefault();
  
      $('.page-videos .video-selected').show();
      $('.page-videos .video-selected iframe').attr('src', $(this).attr('href'));
      $('.page-videos .video-selected .video-title').html($(this).data('title'));
  
      $('html, body').animate({
        scrollTop: $('.video-selected').offset().top - $('#header').height() - 40
      }, 800);
    });
  }

  initVideos();

  $('#video_type_filter').select2();
  $('#video_decade_filter').select2();

  $('#video_type_filter').on('select2:select', function (e) {
    filter_videos();
  });

  $('#video_decade_filter').on('select2:select', function (e) {
    filter_videos();
  });

  function filter_videos() {
    var filter_url = $('.filters').data('url');
    var params = [];

    if ( $('#video_type_filter').val() != '' ) {
      params.push( 'video_type_filter=' + $('#video_type_filter').val() );
    }

    if ( $('#video_decade_filter').val() != '' ) {
      params.push( 'video_decade_filter=' + $('#video_decade_filter').val() );
    }

    $('.video-list').html('<div class="loading"><i class="fal fa-sync fa-spin"></i></div>');
    $('.wp-pagenavi').remove();
    $('.video-selected iframe').attr('src', '');
    $('.video-selected').hide();

    $.ajax({
      url: filter_url + '?' + params.join('&'),
      type: 'GET',
      success: function(res) {
        $('.video-list').html( $(res).find('.video-list').html() );
        $('.video-list').after( $(res).find('.wp-pagenavi') );

        $('.wp-pagenavi .page, .wp-pagenavi .current').each(function(i){
          if ($(this).html() < 10) {
            $(this).html('0' + $(this).html());
          }
      
          if (i > 0) {
            $(this).before('<span class="divider">/</span>');
          }
        });

        initVideos();
      }
    });
  }
	
}