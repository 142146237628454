export default function home_hero() {
	
	$('.home-hero .hero-featured-info__close a').click(function(e){
    e.preventDefault();
    $('.home-hero .hero-featured').hide();
  });

  $('.hero-discover-site a').click(function(e){
    e.preventDefault();
    $('html, body').animate({
      scrollTop: $('.home-about').offset().top - 94
    }, 800);
  });
	
}