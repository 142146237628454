export default function miles101() {

  $('.albums .item').each(function(){
    var _album = this;

    $(this).find('.item-info__actions .btn-view').click(function(e){
      e.preventDefault();
  
      $('.albums .item').not(_album).find('.item-tracks').hide();
      $(_album).find('.item-tracks').slideToggle();      
    });
  });
}