export default function music_albums() {
  $('#album_filter').select2();

  $('#album_filter').on('select2:select', function (e) {
    filter_albums();
  });

  $('.filters .sort-by ul li a').click(function(e){
    e.preventDefault();

    $('.filters .sort-by').removeClass('selected');
    $(this).closest('.sort-by').data('order', $(this).data('order'));
    $(this).closest('.sort-by').addClass('selected');

    $('.sort-title').data('order', 'asc');
    $('#sort-title').html('A - Z');

    filter_albums();
  });

  $('#sort-title').click(function(e){
    e.preventDefault();

    var _parent = $(this).parent();

    $('.filters .sort-by').removeClass('selected');
    $(_parent).addClass('selected');

    if ($(_parent).data('order') == 'asc') {
      $(_parent).data('order', 'desc');
      $(this).html('Z - A');
    } else {
      $(_parent).data('order', 'asc');
      $(this).html('A - Z');
    }

    filter_albums();
  });

  $('#album_search').keypress(function (e) {
    if ( e.keyCode == 13 ) {
      filter_albums();
    }
  });

  function filter_albums() {
    var filter_url = $('.filters').data('url');
    var params = [];

    if ( $('#album_filter').val() != '' ) {
      params.push( 'album_filter=' + $('#album_filter').val() );
    }

    params.push( 'album_sort=' + $('.sort-by.selected').data('sort') );
    params.push( 'album_order=' + $('.sort-by.selected').data('order') );

    if ( $('#album_search').val() != '' ) {
      params.push( 'album_search=' + $('#album_search').val() );
    }

    $('.album-list').html('<div class="loading"><i class="fal fa-sync fa-spin"></i></div>');

    if ($('.sort-by.selected').data('sort') == 'origin_date') {
      $('.album-list').addClass('sort-by-origin-date');
    } else {
      $('.album-list').removeClass('sort-by-origin-date');
    }

    $.ajax({
      url: filter_url + '?' + params.join('&'),
      type: 'GET',
      success: function(res) {
        $('.album-list').html( $(res).find('.album-list').html() );
      }
    });
  }
}