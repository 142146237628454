export default function news() {
  
  $('#news_search').keypress(function (e) {
    if ( e.keyCode == 13 ) {
      filter_news();
    }
  });

  function filter_news() {
    var filter_url = $('.filters').data('url');
    var params = [];

    if ( $('#news_search').val() != '' ) {
      params.push( 'news_search=' + $('#news_search').val() );
    }

    $('.wp-pagenavi').remove();
    $('.news-list').html('<div class="loading"><i class="fal fa-sync fa-spin"></i></div>');

    $.ajax({
      url: filter_url + '?' + params.join('&'),
      type: 'GET',
      success: function(res) {
        $('.news-list').html( $(res).find('.news-list').html() );
        $('.news-list').after( $(res).find('.wp-pagenavi') );

        $('.wp-pagenavi .page, .wp-pagenavi .current').each(function(i){
          if ($(this).html() < 10) {
            $(this).html('0' + $(this).html());
          }
      
          if (i > 0) {
            $(this).before('<span class="divider">/</span>');
          }
        });
      }
    });
  }
}