require( 'owl.carousel' );
import 'magnific-popup';
import 'stupid-table-plugin';
import 'select2';
import 'imagesloaded';
import AOS from 'aos';

import common from './modules/common';
import nav from './modules/nav';
import home_news from './modules/home_news';
import home_video from './modules/home_video';
import home_store from './modules/home_store';
import home_hero from './modules/home_hero';
import home_music from './modules/home_music';

import related_posts from './modules/related_posts';
import awards from './modules/awards';
import photos from './modules/photos';
import videos from './modules/videos';
import about from './modules/about';
import music_chart_history from './modules/music_chart_history';
import music_song_list from './modules/music_song_list';
import music_playlist from './modules/music_playlist';
import music_albums from './modules/music_albums';
import single_comment from './modules/single_comment';
import single_news from './modules/single_news';
import films from './modules/films';
import news from './modules/news';
import collaborators from './modules/collaborators';
import miles101 from './modules/miles101';

$(document).ready(function($) {

  common();

  nav();

  about();

  related_posts();

  collaborators();

  if ($('body').hasClass('page-template-home')) {
    home_news();
    home_video();
    home_store();
    home_hero();
    home_music();
  }

  if ($('body').hasClass('tax-gallery')) {
    photos();
  }

  if ($('body').hasClass('page-template-videos')) {
    videos();
  }

  if ($('body').hasClass('page-template-music-chart-history')) {
    music_chart_history();
  }

  if ($('body').hasClass('page-template-music-song-list')) {
    music_song_list();
  }

  if ($('body').hasClass('page-template-music-playlists')) {
    music_playlist();
  }

  if ($('body').hasClass('page-template-music-albums')) {
    music_albums();
  }

  if ($('body').hasClass('single')) {
    single_comment();
  }

  if ($('body').hasClass('page-template-awards')) {
    awards();
  }

  if ($('body').hasClass('single-post')) {
    single_news();
  }

  if ($('body').hasClass('page-template-films')) {
    films();
  }

  if ($('body').hasClass('page-template-news')) {
    news();
  }

  if ($('body').hasClass('page-template-miles101')) {
    miles101();
  }

  AOS.init({
    duration: 1000,
    offest: 200,
    anchorPlacement: 'top-bottom'
  });
});