export default function common() {

  $('.sub-block-collapse').each(function(){
    var el_block = this;
    var el_title = $(this).find('.sub-block__title');
    el_title.append('<i class="fal fa-plus"></i>');
    var el_icon = el_title.find('i');
    $(el_title).click(function(){
      $(el_icon).toggleClass('fa-plus');
      $(el_icon).toggleClass('fa-times');
      $(el_block).find('.sub-block__content').slideToggle();
    });
  });

  var width = $(window).width();
  var count = $('.releated-posts-container .album-list').data('count');
  var loop = (width < 768 && count >= 2) || (width >= 768 && count >= 4);

  $('.releated-posts-container .album-list').owlCarousel({
    items: 1.5,
    margin: 30,
    dotsEach: true,
    loop: loop,
    nav: true,
    autoplay: false,
    smartSpeed: 1000,
    mouseDrag: false,
    touchDrag: false,
    autoWidth: false,
    autoplayHoverPause: true,
    navText: ['<i class="fal fa-arrow-left"></i>','<i class="fal fa-arrow-right"></i>'],
    responsive: {
      768: {
        items: 3.5
      }
    }
  });

  $('.releated-posts-container .album-list .owl-nav .owl-next').css('top', $('.releated-posts-container .album-list .owl-item').width() / 2);

  $('.releated-posts-container .person-list').each(function(){
    var count = $(this).data('count');
    var loop = (width < 768 && count >= 3) || (width >= 768 && width < 992 && count >= 4) || (width >= 992 && count >= 6);

    $(this).owlCarousel({
      items: 3.5,
      margin: 20,
      dotsEach: true,
      loop: loop,
      nav: true,
      autoplay: false,
      smartSpeed: 1000,
      mouseDrag: false,
      touchDrag: false,
      autoWidth: false,
      navText: ['<i class="fal fa-arrow-left"></i>','<i class="fal fa-arrow-right"></i>'],
      responsive: {
        768: {
          items: 4.5
        },
        992: {
          items: 6.5
        }
      }
    });
    
    $(this).find('.owl-nav .owl-next').css('top', $('.releated-posts-container .person-list .owl-item').width() / 2);
  });

  $('.releated-posts-container .photo-list .item').magnificPopup({
    delegate: 'a',
    type: 'image',
    tLoading: 'Loading image #%curr%...',
    mainClass: 'mfp-img-mobile',
    verticalFit: true,
    closeMarkup: '<img class="mfp-close" src="' + wp.site_url + '/wp-content/themes/milesdavis/dist/img/close.png">',
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0,1],
      arrowMarkup: '<img class="btn-%dir%" src="' + wp.site_url + '/wp-content/themes/milesdavis/dist/img/arrow-%dir%.png">', 
      tCounter: '<span>%curr%</span> / %total%'
    },
    image: {
      tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
      titleSrc: function(item) {
        return item.el.data('credit');
      }
    },
    callbacks: {
      change: function(){
        console.log($('.mfp-counter span').html());
        if (this.index < 9) {
          var _num = this.index + 1;
          setTimeout(function(){
            $('.mfp-counter span').html('0' + _num);
          }, 10)
          
        }
      }
    }
  });

  var count = $('.releated-posts-container .photo-list').data('count');
  var loop = (width < 768 && count >= 2) || (width >= 768 && count >= 4);

	$('.releated-posts-container .photo-list').owlCarousel({
    items: 1.3,
    margin: 30,
    dotsEach: true,
    loop: loop,
    nav: true,
    autoplay: false,
    smartSpeed: 1000,
    mouseDrag: false,
    touchDrag: false,
    autoWidth: false,
    autoplayHoverPause: true,
    navText: ['<i class="fal fa-arrow-left"></i>','<i class="fal fa-arrow-right"></i>'],
    responsive: {
      768: {
        items: 3.5
      }
    }
  });

  $('.releated-posts-container .photo-list .owl-item.cloned .item a').click(function(e){
    e.preventDefault();

    var img_src = $(this).attr('href');

    $('.releated-posts-container .photo-list .owl-item:not(.cloned)').each(function(){
      var main_a = $(this).find('a');
      if (main_a.attr('href') == img_src) {
        main_a.click();
      }
    });
  });

  var count = $('.releated-posts-container .video-list').data('count');
  var loop = (width < 768 && count >= 2) || (width >= 768 && count >= 3);

  $('.releated-posts-container .video-list').owlCarousel({
    items: 1.35,
    margin: 30,
    dotsEach: true,
    loop: loop,
    nav: true,
    autoplay: false,
    smartSpeed: 1000,
    mouseDrag: false,
    touchDrag: false,
    autoWidth: false,
    navText: ['<i class="fal fa-arrow-left"></i>','<i class="fal fa-arrow-right"></i>'],
    responsive: {
      768: {
        items: 2.5
      }
    }
  });

  $('.releated-posts-container .video-list .owl-nav .owl-next').css('top', $('.releated-posts-container .video-list .video-wrapper').outerHeight() / 2);

  $('.releated-posts-container .video-list .item a').magnificPopup({
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false,
    closeMarkup: '<img class="mfp-close" src="' + wp.site_url + '/wp-content/themes/milesdavis/dist/img/close.png">',
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0,1],
      arrowMarkup: '<img class="btn-%dir%" src="' + wp.site_url + '/wp-content/themes/milesdavis/dist/img/arrow-%dir%.png">', 
      tCounter: '<span>%curr%</span> / %total%'
    },
  });

  var count = $('.releated-posts-container .news-list').data('count');
  var loop = (width < 768 && count >= 2) || (width >= 768 && count >= 3);

  $('.releated-posts-container .news-list').owlCarousel({
    items: 1.5,
    margin: 30,
    dotsEach: true,
    loop: loop,
    nav: true,
    autoplay: false,
    smartSpeed: 1000,
    mouseDrag: false,
    touchDrag: false,
    autoWidth: false,
    autoplayHoverPause: true,
    navText: ['<i class="fal fa-arrow-left"></i>','<i class="fal fa-arrow-right"></i>'],
    responsive: {
      768: {
        items: 3
      }
    }
  });

  var count = $('.releated-posts-container .liner-note-list').data('count');
  var loop = (width < 768 && count >= 2) || (width >= 768 && count >= 4);

  $('.releated-posts-container .liner-note-list').owlCarousel({
    items: 1.5,
    margin: 30,
    dotsEach: true,
    loop: loop,
    nav: true,
    autoplay: false,
    smartSpeed: 1000,
    mouseDrag: false,
    touchDrag: false,
    autoWidth: false,
    autoplayHoverPause: true,
    navText: ['<i class="fal fa-arrow-left"></i>','<i class="fal fa-arrow-right"></i>'],
    responsive: {
      768: {
        items: 3.5
      }
    }
  });

  $('.releated-posts-container .liner-note-list .item').magnificPopup({
    delegate: 'a',
    type: 'image',
    tLoading: 'Loading image #%curr%...',
    mainClass: 'mfp-img-mobile',
    verticalFit: true,
    image: {
      tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
      titleSrc: function(item) {
        return item.el.attr('title');
      }
    }
  });

  var count = $('.related-merch .merch-list .item').length;
  
  if (width < 768) {
    $('.related-merch .merch-list .item:eq(2)').addClass('hide');
    
    if (count > 2) {
      $('.related-merch .merch-more').show();
    } else {
      $('.related-merch .merch-more').hide();
    }
  }

  $('.related-merch .merch-more a').click(function(e){
    e.preventDefault();

    $('.related-merch .merch-more').hide();
    $('.related-merch .merch-list .item').removeClass('hide');
  });
  

  $('.award-list .item').each(function(){
    $(this).find('.award-photos').magnificPopup({
      delegate: 'a',
      type: 'image',
      tLoading: 'Loading image #%curr%...',
      mainClass: 'mfp-img-mobile',
      verticalFit: true,
      closeMarkup: '<img class="mfp-close" src="' + wp.site_url + '/wp-content/themes/milesdavis/dist/img/close.png">',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0,1],
        arrowMarkup: '<img class="btn-%dir%" src="' + wp.site_url + '/wp-content/themes/milesdavis/dist/img/arrow-%dir%.png">', 
        tCounter: '<span>%curr%</span> / %total%'
      },
      image: {
        tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
        titleSrc: function(item) {
          return item.el.attr('title');
        }
      },
      callbacks: {
        change: function(){
          console.log($('.mfp-counter span').html());
          if (this.index < 9) {
            var _num = this.index + 1;
            setTimeout(function(){
              $('.mfp-counter span').html('0' + _num);
            }, 10)
            
          }
        }
      }
    });

    $(this).find('.item-links a.photo').click(function(e){
      e.preventDefault();
      $(this).parent().find('.award-photos a:eq(0)').click();
    });
  });

  $('.award-list .item').each(function(){
    $(this).find('.award-videos').magnificPopup({
      delegate: 'a',
      type: 'iframe',
      mainClass: 'mfp-fade',
      verticalFit: true,
      closeMarkup: '<img class="mfp-close" src="' + wp.site_url + '/wp-content/themes/milesdavis/dist/img/close.png">',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0,1],
        arrowMarkup: '<img class="btn-%dir%" src="' + wp.site_url + '/wp-content/themes/milesdavis/dist/img/arrow-%dir%.png">', 
        tCounter: '<span>%curr%</span> / %total%'
      },
      callbacks: {
        change: function(){
          console.log($('.mfp-counter span').html());
          if (this.index < 9) {
            var _num = this.index + 1;
            setTimeout(function(){
              $('.mfp-counter span').html('0' + _num);
            }, 10)
            
          }
        }
      }
    });

    $(this).find('.item-links a.video').click(function(e){
      e.preventDefault();
      $(this).parent().find('.award-videos a:eq(0)').click();
    });
  });



  $('.album-info__content .photo-list .item').magnificPopup({
    delegate: 'a',
    type: 'image',
    tLoading: 'Loading image #%curr%...',
    mainClass: 'mfp-img-mobile',
    verticalFit: true,
    closeMarkup: '<img class="mfp-close" src="' + wp.site_url + '/wp-content/themes/milesdavis/dist/img/close.png">',
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0,1],
      arrowMarkup: '<img class="btn-%dir%" src="' + wp.site_url + '/wp-content/themes/milesdavis/dist/img/arrow-%dir%.png">', 
      tCounter: '<span>%curr%</span> / %total%'
    },
    image: {
      tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
      titleSrc: function(item) {
        return item.el.data('credit');
      }
    },
    callbacks: {
      change: function(){
        console.log($('.mfp-counter span').html());
        if (this.index < 9) {
          var _num = this.index + 1;
          setTimeout(function(){
            $('.mfp-counter span').html('0' + _num);
          }, 10)
          
        }
      }
    }
  });

  var count = $('.album-info__content .photo-list').data('count');
  var loop = (width < 768 && count >= 2) || (width >= 768 && count >= 3);

	$('.album-info__content .photo-list').owlCarousel({
    items: 1.3,
    margin: 30,
    dotsEach: true,
    loop: loop,
    nav: true,
    autoplay: false,
    smartSpeed: 1000,
    mouseDrag: false,
    touchDrag: false,
    autoWidth: false,
    autoplayHoverPause: true,
    navText: ['<i class="fal fa-arrow-left"></i>','<i class="fal fa-arrow-right"></i>'],
    responsive: {
      768: {
        items: 2.5
      }
    }
  });

  $('.album-info__content .photo-list .owl-item.cloned .item a').click(function(e){
    e.preventDefault();

    var img_src = $(this).attr('href');

    $('.album-info__content .photo-list .owl-item:not(.cloned)').each(function(){
      var main_a = $(this).find('a');
      if (main_a.attr('href') == img_src) {
        main_a.click();
      }
    });
  });

  var count = $('.album-info__content .video-list').data('count');
  var loop = (width < 768 && count >= 2) || (width >= 768 && count >= 2);

  $('.album-info__content .video-list').owlCarousel({
    items: 1.35,
    margin: 30,
    dotsEach: true,
    loop: loop,
    nav: true,
    autoplay: false,
    smartSpeed: 1000,
    mouseDrag: false,
    touchDrag: false,
    autoWidth: false,
    navText: ['<i class="fal fa-arrow-left"></i>','<i class="fal fa-arrow-right"></i>'],
    responsive: {
      768: {
        items: 1.5
      }
    }
  });

  $('.album-info__content .video-list .owl-nav .owl-next').css('top', $('.album-info__content .video-list .video-wrapper').outerHeight() / 2);

  $('.album-info__content .video-list .item a').magnificPopup({
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false,
    closeMarkup: '<img class="mfp-close" src="' + wp.site_url + '/wp-content/themes/milesdavis/dist/img/close.png">',
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0,1],
      arrowMarkup: '<img class="btn-%dir%" src="' + wp.site_url + '/wp-content/themes/milesdavis/dist/img/arrow-%dir%.png">', 
      tCounter: '<span>%curr%</span> / %total%'
    },
  });
}