export default function home_video() {
	
	$('.home-video .video-list').owlCarousel({
    items: 1,
    dotsEach: true,
    loop: true,
    nav: true,
    autoplay: false,
    smartSpeed: 1000,
    mouseDrag: true,
    touchDrag: false,
    autoplayHoverPause: true,
    navText: ['<i class="fal fa-arrow-left"></i>','<i class="fal fa-arrow-right"></i>'],
    onInitialized: function(event) {
      console.log(event);
      $('.home-video .owl-page .current').html(('0' + 1).slice(-2));
      $('.home-video .owl-page .count').html(('0' + event.item.count).slice(-2));
    },
    onChanged: function(event) {
      $('.home-video .owl-page .current').html(('0' + (event.page.index + 1)).slice(-2));
      $('.home-video .owl-page .count').html(('0' + event.page.count).slice(-2));
    },
    responsive: {
      768: {
        items: 1.2
      }
    }
  });

  $('.home-video .video-list .item a').magnificPopup({
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false,
    closeMarkup: '<img class="mfp-close" src="' + wp.site_url + '/wp-content/themes/milesdavis/dist/img/close.png">',
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0,1],
      arrowMarkup: '<img class="btn-%dir%" src="' + wp.site_url + '/wp-content/themes/milesdavis/dist/img/arrow-%dir%.png">', 
      tCounter: '<span>%curr%</span> / %total%'
    },
  });

  // var tag = document.createElement('script');
  // tag.src = "//www.youtube.com/player_api";
  // var firstScriptTag = document.getElementsByTagName('script')[0];
  // firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  // function onYouTubePlayerAPIReady() {
  //   if (wp.yt_api_ready) {
  //     initVideoMedia();
  //   } else {
  //     setTimeout(onYouTubePlayerAPIReady, 200);
  //   }
  // }

  // function initVideoMedia() {
  //   var current_player;

  //   $('.home-video .video-list .item').each(function(){
  //     var frame_id = $(this).find('iframe').attr('id');
  //     if (frame_id) {
  //       var instance = this;
  //       var player = new YT.Player(frame_id, {
  //         events: {
  //           onReady: function(){
  //             $(instance).find('.btn-player').click(function(e){
  //               e.preventDefault();
  //               $('.home-video .video-list .item .thumb').show();
  //               if (current_player) {
  //                 current_player.pauseVideo();
  //               }
  //               player.playVideo();
  //               $(instance).find('.thumb').hide();
  //               current_player = player;
  //             });
  //           }
  //         }
  //       });
  //     }
  //   });
  // }

  // onYouTubePlayerAPIReady();
	
}