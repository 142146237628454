export default function home_music() {
	
	$('.home-music .music-list .item').each(function(){
    var music_instance = this;
    $(this).find('.info-links a').click(function(e){
      e.preventDefault();
      $(music_instance).find('.item-tracks').slideToggle();
    });
  });

}