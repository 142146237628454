export default function about() {

  var sub_menu_vert = document.createElement('ul');
  $('.timeline-menu-vertical .current-menu-item').append(sub_menu_vert);
  var sub_menu_hori = document.createElement('ul');
  $('.timeline-menu-horizontal .current-menu-item').append(sub_menu_hori);

  $('[id^=about-]').each(function(){
    var id = $(this).attr('id');
    var year = id.split('-')[1];
    
    $(sub_menu_vert).append('<li><a href="#' + id + '">' + year + '</a></li>');
  });

  $(sub_menu_vert).find('a').click(function(e) {
    e.preventDefault();
    
		if (this.hash !== "") {
      // Store hash
      var hash = this.hash;
      var pos = $(hash).offset().top - $('#header').outerHeight();

      $('html, body').animate({
        scrollTop: pos
      }, 800);
    }
  });

  $('.timeline-list .item').each(function(i){
    $(this).data('page', i);
  });

  var timeline_owl = $('.timeline-list').owlCarousel({
    items: 1,
    dotsEach: true,
    loop: false,
    nav: true,
    margin: 30,
    smartSpeed: 1000,
    mouseDrag: true,
    touchDrag: true,
    navText: ['<i class="fal fa-arrow-left"></i>','<i class="fal fa-arrow-right"></i>'],
    responsive: {
      768: {
        items: 2
      },
      992: {
        items: 3
      }
    }
  });

  $(sub_menu_hori).html($('.sub-timeline-years').html());

  $(sub_menu_hori).find('a').each(function(){
    $(this).data('page', $('#' + $(this).data('section')).data('page'));
  });

  $(sub_menu_hori).find('a').click(function(e) {
    e.preventDefault();
    
    var total_pages = timeline_owl.find('.owl-dot').length;
    console.log(Math.min(total_pages, $(this).data('page')));
		timeline_owl.trigger('to.owl.carousel', [Math.min(total_pages - 1, $(this).data('page')), 700]);
  });


  initPhotoPopup();
  initVideoPopup();

  function initPhotoPopup() {
    $('.timeline-list .item-award').each(function(){
      $(this).find('.award-photos').magnificPopup({
        delegate: 'a',
        type: 'image',
        tLoading: 'Loading image #%curr%...',
        mainClass: 'mfp-img-mobile',
        verticalFit: true,
        closeMarkup: '<img class="mfp-close" src="' + wp.site_url + '/wp-content/themes/milesdavis/dist/img/close.png">',
        gallery: {
          enabled: true,
          navigateByImgClick: true,
          preload: [0,1],
          arrowMarkup: '<img class="btn-%dir%" src="' + wp.site_url + '/wp-content/themes/milesdavis/dist/img/arrow-%dir%.png">', 
          tCounter: '<span>%curr%</span> / %total%'
        },
        image: {
          tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
          titleSrc: function(item) {
            return item.el.attr('title');
          }
        },
        callbacks: {
          change: function(){
            console.log($('.mfp-counter span').html());
            if (this.index < 9) {
              var _num = this.index + 1;
              setTimeout(function(){
                $('.mfp-counter span').html('0' + _num);
              }, 10)
              
            }
          }
        }
      });

      $(this).find('.award-links a.photo').click(function(e){
        e.preventDefault();
        $(this).parent().find('.award-photos a:eq(0)').click();
      });
    });
  }

  function initVideoPopup() {
    $('.timeline-list .item-award').each(function(){
      $(this).find('.award-videos').magnificPopup({
        delegate: 'a',
        type: 'iframe',
        mainClass: 'mfp-fade',
        verticalFit: true,
        closeMarkup: '<img class="mfp-close" src="' + wp.site_url + '/wp-content/themes/milesdavis/dist/img/close.png">',
        gallery: {
          enabled: true,
          navigateByImgClick: true,
          preload: [0,1],
          arrowMarkup: '<img class="btn-%dir%" src="' + wp.site_url + '/wp-content/themes/milesdavis/dist/img/arrow-%dir%.png">', 
          tCounter: '<span>%curr%</span> / %total%'
        },
        callbacks: {
          change: function(){
            console.log($('.mfp-counter span').html());
            if (this.index < 9) {
              var _num = this.index + 1;
              setTimeout(function(){
                $('.mfp-counter span').html('0' + _num);
              }, 10)
              
            }
          }
        }
      });

      $(this).find('.award-links a.video').click(function(e){
        e.preventDefault();
        $(this).parent().find('.award-videos a:eq(0)').click();
      });
    });
  }

}