export default function common() {

  $('.btn-drop-group > .btn').click(function(e){
    e.preventDefault();
  });

  $('.share-bar > a').click(function(e){
    e.preventDefault();

    $(this).find('i').toggleClass('fa-share-alt');
    $(this).find('i').toggleClass('fa-times');

    $('.share-bar').toggleClass('open');
  });

  // Social popup open
	$('.share-bar ul li a').click(function(e){
		e.preventDefault();
		const link = $(this).attr("href");
		const w = 670;
		const h = 378;
		const y = window.outerHeight / 2 + window.screenY - (h / 2)
		const x = window.outerWidth / 2 + window.screenX - (w / 2)
		window.open(link, "_blank", 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + y + ', left=' + x);
	});
  
  /*$('.btn-subscribe').magnificPopup({
    type: 'inline',
    closeOnBgClick: false
  });*/

  $('.wp-pagenavi .page, .wp-pagenavi .current').each(function(i){
    if ($(this).html() < 10) {
      $(this).html('0' + $(this).html());
    }

    if (i > 0) {
      $(this).before('<span class="divider">/</span>');
    }
  });

  $('.lsap-track-actions .lsap-video-link').magnificPopup({
    disableOn: 700,
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false,
    closeMarkup: '<img class="mfp-close" src="' + wp.site_url + '/wp-content/themes/milesdavis/dist/img/close.png">',
  });


  $('.socials .icon-voice').magnificPopup({
    delegate: 'a',
    type: 'image',
    tLoading: 'Loading image #%curr%...',
    mainClass: 'mfp-img-mobile',
    verticalFit: true,
    closeMarkup: '<img class="mfp-close" src="' + wp.site_url + '/wp-content/themes/milesdavis/dist/img/close.png">',
    image: {
      tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
      titleSrc: function(item) {
        return item.el.data('credit');
      }
    }
  });
}