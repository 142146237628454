import Masonry from 'masonry-layout';

export default function photos() {

  function initMasonry() {
    new Masonry('.page-gallery .photos', {
      itemSelector: '.item',
      columnWidth: '.item',
      percentPosition: true,
    });
  }

  function initPhotoPopup() {
    $('.page-gallery .photos .item').magnificPopup({
      delegate: 'a',
      type: 'image',
      tLoading: 'Loading image #%curr%...',
      mainClass: 'mfp-img-mobile',
      verticalFit: true,
      closeMarkup: '<img class="mfp-close" src="' + wp.site_url + '/wp-content/themes/milesdavis/dist/img/close.png">',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0,1],
        arrowMarkup: '<img class="btn-%dir%" src="' + wp.site_url + '/wp-content/themes/milesdavis/dist/img/arrow-%dir%.png">', 
        tCounter: '<span>%curr%</span> / %total%'
      },
      image: {
        tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
        titleSrc: function(item) {
          var title = item.el.data('content');
          if (item.el.data('credit')) {
            if (title) title += '<br>';
            title += item.el.data('credit');
          } 
          return title;
        }
      },
      callbacks: {
        change: function(){
          console.log($('.mfp-counter span').html());
          if (this.index < 9) {
            var _num = this.index + 1;
            setTimeout(function(){
              $('.mfp-counter span').html('0' + _num);
            }, 10)
            
          }
        }
      }
    });
  }

  $(document).ready(function() {
    initMasonry();
    initPhotoPopup();

    $('.page-gallery').imagesLoaded(function(){
      initMasonry();
    });
  });

  $(window).resize(function(){
    initMasonry();
  });
	
}