export default function music_song_list() {

  var table = $('.sortable-table').stupidtable();
  var anchor = '';
  var current_playing_id = 0;

  $('.page-music-song-list .filter .anchors a').click(function(e){
    // e.preventDefault();

    // anchor = $(this).data('anchor');
    // $('.songlist-table-wrapper table thead th:eq(1)').stupidsort('asc');
  });
  
  table.bind('aftertablesort', function (event, data) {
    if (data.column == 1 && anchor != '') {
      $(this).find('tbody tr').each(function () {
        const track_title = $(this).find('td:eq(1)').text();
        const first_letter = track_title.trim().substring(0, 1).toLowerCase();
  
        if (first_letter === anchor) {
          const top = $(this).offset().top - 100;
  
          anchor = '';
          $('html, body').animate({scrollTop: top}, 800);
          return false;
        }
      });
    }
  });

  $('.songlist-table-wrapper .action-play a').click(function(e){
    e.preventDefault();

    $('.songlist-table-wrapper .action-play a').not(this).each(function(){
      $(this).find('i').addClass('fa-play');
      $(this).find('i').removeClass('fa-pause');
    });

    $(this).find('i').toggleClass('fa-play');
    $(this).find('i').toggleClass('fa-pause');

    var track_id = $(this).parent().parent().data('track-id');

    $('.lsap-item[data-track-id=' + track_id + '] .lsap-track-controls-play-pause').click();

    if ($(this).find('i').hasClass('fa-pause')) {
      current_playing_id = track_id;
      setTimeout(function(){
        updateProgressBar(track_id);
      }, 50);

      var top = $(this).parent().parent().position().top;
			var popup = $('.songlist-table-wrapper .stream-popup');

      popup.css('top', top - popup.outerHeight() + 10).fadeIn();
      setTimeout( function () {
        popup.fadeOut();
      }, 8000);
    } else {
      current_playing_id = 0;
    }
  });

  function updateProgressBar(track_id) {
    if (current_playing_id == 0 || current_playing_id != track_id) return;

    var lsap_progressbar = $('.lsap-item[data-track-id=' + track_id + '] .lsap-track-progress');
    $('.songlist-table-wrapper tr[data-track-id=' + track_id + '] .track-playing-progress').width(lsap_progressbar.width());

    if (lsap_progressbar.width() >= $('.songlist-table-wrapper').width()) {
      $('.songlist-table-wrapper tr[data-track-id=' + track_id + '] .action-play a i').addClass('fa-play');
      $('.songlist-table-wrapper tr[data-track-id=' + track_id + '] .action-play a i').removeClass('fa-pause');
      lsap_progressbar.width(0);
    } else {
      setTimeout(function(){
        updateProgressBar(track_id);
      }, 50);
    }
  }

  $('.songlist-table-wrapper .stream-service-selection ul li button').click(function(e){
    e.preventDefault();
    $('.songlist-table-wrapper .stream-service-selection ul li button').removeClass('active');
    $(this).addClass('active');
    $('.lsap-stream-service-selection button[data-service=' + $(this).data('service') + ']').click();
  });

  $('.songlist-table-wrapper table tr .action-info .library-add').click(function(e){
    e.preventDefault();
    var track_id = $(this).parent().parent().data('track-id');
    $('.lsap-item[data-track-id=' + track_id + '] .lsap-track-actions .lsap-library-add').click();
  });

  // Search
	$('.page-music-song-list .filter .search input[type=text]').keyup(function(e){
    var s_wrapper = $(this).parent();
		// if (e.keyCode == 13) {
      const search = $(this).val().toLowerCase();
  
      if (search) {
        $('.sortable-table tbody tr').each(function () {
          const search_text = $(this).find('td:not(:last-child)').text().toLowerCase();
          if (!search_text.includes(search)) {
            $(this).hide();
          } else {
            $(this).show();
          }
        });
      } else {
        $('.sortable-table tbody tr').show();
      }
    // }
  });

  // $(window).scroll(function(){
  //   var header = $('#header');
  //   var interior_menu = $('.interior-menu');
  //   var filter = $('.page-music-song-list .filter');

  //   if ($(window).scrollTop() + header.outerHeight() > interior_menu.offset().top + interior_menu.outerHeight() + 10) {
  //     filter.addClass('filter-sticky');
  //     filter.css('top', header.outerHeight() + 10);
  //     filter.outerWidth($('.songlist-table-wrapper').width());
  //   } else {
  //     filter.removeClass('filter-sticky');
  //     filter.css('top', 'auto');
  //     filter.width('auto');
  //   }
  // });
}